import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Portfolio from "../components/Portfolio";

let IndexPage = ({ data, location }) => (
  <Layout location={location}>
    <SEO title="Portfolio" />
    <Portfolio
      images={data.allFile.edges}
      projects={data.projects.edges}
      projectCategories={data.projectCategories.edges}
    />
  </Layout>
);

export const query = graphql`query PortfolioImgQuery {
  allFile(filter: {relativeDirectory: {eq: "portfolio"}}) {
    edges {
      node {
        id
        name
        childImageSharp {
          gatsbyImageData(quality: 50, layout: FULL_WIDTH)
        }
      }
    }
  }
  projectCategories: allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "project-category"}}}
    sort: {fields: frontmatter___order, order: ASC}
  ) {
    edges {
      node {
        frontmatter {
          name
          description
          title
        }
      }
    }
  }
  projects: allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "project"}}}
    sort: {fields: fields___nameInLowerCase, order: ASC}
  ) {
    edges {
      node {
        frontmatter {
          category
          description
          link
          name
          order
          title
          status
          logo {
            childImageSharp {
              gatsbyImageData(quality: 50, layout: FULL_WIDTH)
            }
          }
          background {
            childImageSharp {
              gatsbyImageData(quality: 50, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`;

export default IndexPage;
