import React, { Component } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from './index.module.scss';

class Project extends Component {
	constructor(props) {
		super(props);
		this.project = null;
	}

	render() {
		const { details } = this.props;

		return (
            <div
				className={`${styles.project} transition-all duration-300 ease-out`}
				ref={(project) => (this.project = project)}
			>
				<div className={`${styles.bg} h-full`}>
					<a href={details.link} target="_blank" rel="noopener noreferrer">
						{details.background && (
							<GatsbyImage
                                image={details.background.childImageSharp.gatsbyImageData}
								alt=""
                                className="h-full" />
						)}
					</a>
				</div>
				<div
					className={`${styles.name} small-copy font-title uppercase tracking-wider z-50 flex flex-col`}
				>
					{details.status && (
						<div className="w-full">
							{details.status.map((s) => (
								<div className="badge text-sm md:text-lg text-white leading-0 inline-block mr-2">
									{s}
								</div>
							))}
						</div>
					)}
					<div className="w-full md:hidden">
						<span>{details.name}</span>
					</div>
				</div>
				<a
					href={details.link}
					target="_blank"
					rel="noopener noreferrer"
					className="absolute md:relative w-full h-full top-0 left-0 z-50"
				>
					<div className={`${styles.hidden}`}>
						<div className={styles.hiddenTop}>
							<div className={styles.description}>{details.description}</div>
						</div>
						<div className={`${styles.hiddenBottom} absolute bottom-0`}>
							<div
								className={`${styles.name} captions font-title uppercase tracking-wide`}
							>
								{details.name}
							</div>
							<div className={`${styles.logo} flex h-full`}>
								{details.logo && (
									<GatsbyImage
                                        image={details.logo.childImageSharp.gatsbyImageData}
										alt=""
                                        className="self-end w-full" />
								)}
							</div>
						</div>
					</div>
				</a>
			</div>
        );
	}
}

export default Project;
