import React, { Component } from "react";
import * as styles from "./index.module.scss";
import Project from "../Project";
import loadStates from "../../../hocs/loadStates";
import { TimelineMax, Sine } from "gsap";
import { ScrollToPlugin } from "gsap/all";
import { HtmlParser } from "../../utils/helperFunctions";

const plugins = [ScrollToPlugin]; // eslint-disable-line no-unused-vars

class ProjectContainer extends Component {
  constructor(props) {
    super(props);
    this.allFirstCol = null;
    this.enterpriseFirstCol = null;
    this.consumerFirstCol = null;
    this.techFirstCol = null;
    this.allSecondCol = null;
    this.enterpriseSecondCol = null;
    this.consumerSecondCol = null;
    this.techSecondCol = null;
    this.allMobileCol = null;
    this.enterpriseMobileCol = null;
    this.consumerMobileCol = null;
    this.techMobileCol = null;
    this.container = null;
  }

  getFilteredCategory = () => this.props.categoryFilter.toFilter;

  toFilter = (category, projects) =>
    projects.filter((project) =>
      project.node.frontmatter.category.includes(category)
    );

  filterFirstColumnProjects = (projects) => {
    let counter = 0;
    return projects.filter((project, i) => {
      if (i - 4 * counter === 6) {
        counter++;
      }

      return i % 4 === 0 || i - 4 * counter === 5 || i === 0 || i === 1;
    });
  };

  filterSecondColumnProjects = (projects) => {
    let counter = 0;
    return projects.filter((project, i) => {
      if (i - 4 * counter === 8) {
        counter++;
      }

      return (
        i - 4 * counter === 6 || i - 4 * counter === 7 || i === 2 || i === 3
      );
    });
  };

  componentDidUpdate() {
    const { state } = this.props.categoryFilter;

    //Filter Animation
    if (state === "fade-out") {
      new TimelineMax()
        .to(this.container, 0.2, { autoAlpha: 0, ease: Sine.easeOut })
        .to(
          window,
          0.5,
          {
            scrollTo: { y: "#projectContainer" },
            ease: Sine.easeInOut,
          },
          "+=0.1"
        )
        .to(this.container, 0.3, { autoAlpha: 1, ease: Sine.easeOut }, "-=0.2");
    }
  }
  render() {
    let projects = this.props.projects;
    const { projectCategories } = this.props;

    const curCat = this.getFilteredCategory();
    projects = curCat === "all" ? projects : this.toFilter(curCat, projects);

    const projectCategoryDetail = projectCategories.find(
      (category) =>
        category.node.frontmatter.name === this.getFilteredCategory()
    );

    return (
      <>
        <div
          id="projectContainer"
          className={styles.projectContainerLine}
        ></div>
        {projectCategoryDetail &&
          projectCategoryDetail.node.frontmatter.description && (
            <div className="w-3/12 md:w-full pt-10 md:pt-40 md:-mb-20">
              <h3 className="text-3xl">
                {projectCategoryDetail.node.frontmatter.title}
              </h3>
              <div
                className={`${styles.categoryDetail} text-xl mb-10 md:mb-0 md-container`}
              >
                {projectCategoryDetail.node.frontmatter.description && (
                  <HtmlParser
                    source={projectCategoryDetail.node.frontmatter.description}
                  />
                )}
              </div>
            </div>
          )}
        <div
          className={`${styles.projectContainer} z-50`}
          ref={(container) => (this.container = container)}
        >
          {typeof window !== "undefined" && window.innerWidth > 768 ? (
            <>
              <div className={styles.leftColumnContainer}>
                <div
                  className={styles.leftColumn}
                  ref={(col) => (this.allFirstCol = col)}
                >
                  {this.filterFirstColumnProjects(projects).map(
                    (project, i) => {
                      return (
                        <Project
                          details={project.node.frontmatter}
                          key={i}
                          filter={this.getFilteredCategory()}
                        />
                      );
                    }
                  )}
                </div>
              </div>
              <div className={styles.rightColumnContainer}>
                <div
                  className={styles.rightColumn}
                  ref={(col) => (this.allSecondCol = col)}
                >
                  {this.filterSecondColumnProjects(projects).map(
                    (project, i) => {
                      return (
                        <Project details={project.node.frontmatter} key={i} />
                      );
                    }
                  )}
                </div>
              </div>
            </>
          ) : null}
          {typeof window !== "undefined" && window.innerWidth <= 768 && (
            <>
              <div
                className={`${styles.mobileContainer} w-full`}
                ref={(col) => (this.allMobileCol = col)}
              >
                {projects.map((project, i) => {
                  return (
                    <Project
                      details={project.node.frontmatter}
                      key={i}
                      order={i}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default loadStates(ProjectContainer);
