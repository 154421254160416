import React, { Component } from "react";
import * as styles from "./index.module.scss";
import Category from "./Category";
import { connect } from "react-redux";
import { filterCategory, changeFilterState } from "../../../state/actions";
class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pause: false
    };
  }
  setPause = () => {
    this.setState({ pause: true });
  };
  cancelPause = () => {
    this.setState({ pause: false });
  };

  componentDidMount(){
    this.props.filter('all')
  }
  render() {
    const { categories, pause, isPortfolioPage, changeFilterState, filter, categoryFilter } = this.props;
    return (
      <ul
        className={`${styles.filter} ${isPortfolioPage &&
          styles.filterPortfolio} flex`}
      >
        {categories.map((category, i) => {
          return (
            <li
              key={i}
              className={`flex whitespace-no-wrap`}
            >
              {i ? (
                <span
                  className={`${styles.separator} title title-sm mx-4 mt-1`}
                >
                  /
                </span>
              ) : (
                ""
              )}
              <Category
                pause={pause}
                name={category.name}
                setPause={() => this.setPause()}
                cancelPause={() => this.cancelPause()}
                pauseState={this.state.pause}
                isPortfolioPage={isPortfolioPage}
                changeFilterState={changeFilterState}
                filter={filter}
                categoryFilter={categoryFilter}
              >
                {category.displayName}
              </Category>
            </li>
          );
        })}
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    filter: (category) => dispatch(filterCategory(category)),
    changeFilterState: (state) => dispatch(changeFilterState(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);