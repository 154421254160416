import React from "react";
import * as styles from "./index.module.scss";
import PageTitle from "../utils/PageTitle";
import Filter from "../utils/Filter";
import ProjectContainer from "./ProjectContainer";

export default function Portfolio({ projects, projectCategories }) {
  const categoryNamesArray = projects.map(project => project.node.frontmatter.category).flat()
	const categoriesWithProjects = [...new Set(categoryNamesArray)]
	const categoriesWithProjectsMapped = projectCategories.filter(category => categoriesWithProjects.includes(category.node.frontmatter.name))
	const catNames = categoriesWithProjectsMapped.map(c => {
		return {
			name: c.node.frontmatter.name,
			displayName: c.node.frontmatter.title
		} 
	});
	catNames.unshift({name: 'all', displayName: "All"})
	const catNamesWithAll = catNames
  return (
    <section className={`page-section-top`}>
      <div className={`section-container section-container--withFilter`}>
        <PageTitle title="Portfolio" color="yellow" />

        <div className={`${styles.filterLandscape} md:hidden`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.58 32.35">
            <defs>
              <clipPath id="clip-logo-portfolio">
                <rect
                  className={styles.cls1}
                  x="8.25"
                  y="448.66"
                  width="29.74"
                  height="93.83"
                />
              </clipPath>
            </defs>
            <polygon
              className={styles.cls3}
              points="36.58 10.71 24.08 32.35 14.87 32.35 17.64 27.55 21.31 27.55 31.03 10.71 36.58 10.71"
            />
            <polygon
              className={styles.cls3}
              points="6.98 0 31.97 0 36.58 7.98 31.03 7.98 29.2 4.8 9.75 4.8 6.98 0"
            />
            <polygon
              className={styles.cls3}
              points="12.49 31.06 0 9.41 4.61 1.44 7.38 6.24 5.54 9.42 15.27 26.26 12.49 31.06"
            />
          </svg>
          <Filter categories={catNamesWithAll} pause isPortfolioPage />
        </div>
        <div
          className={`${styles.filterContainerMobile} filter-container hidden md:block md:fixed`}
        >
          <Filter categories={catNamesWithAll} pause isPortfolioPage />
        </div>
        <ProjectContainer
          projects={projects}
          projectCategories={projectCategories}
        />
      </div>
    </section>
  );
}
