/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import * as styles from './index.module.scss';

function Category({
	children,
	filter,
	name,
	cancelPause,
	setPause,
	pauseState,
	categoryFilter,
	changeFilterState,
	isPortfolioPage
}) {
	const handleClick = () => {
		if (isPortfolioPage && pauseState === false) {
			setPause();
			setTimeout(() => {
				cancelPause();
			}, 1000);
			changeFilterState('fade-out');
			setTimeout(() => {
				filter(name);
			}, 300);
			// setTimeout(() => {
			// 	changeFilterState('fade-in');
			// }, 710);
		} else {
			filter(name);
		}
	};
	return (
		<div
			className={`${styles.filterItem} ${isPortfolioPage &&
				`${styles.filterItemPortfolio} font-body`} ${categoryFilter.toFilter ===
				name && styles.active} title title-sm font-bold cursor-pointer brand-grey-text`}
			onClick={handleClick}
			role="button"
			aria-label="select category button"
			tabIndex="0"
		>
			{children}
		</div>
	);
}

export default Category;
