import React, { useEffect, useRef } from "react";
import * as styles from "./index.module.scss";

function PageTitle({ title, isVisible, color }) {
  //Ref
  const crossOne = useRef(null);
  const crossTwo = useRef(null);
  const coverTop = useRef(null);
  const coverBottom = useRef(null);
  const container = useRef(null);
  const yellowBlock = useRef(null);

  useEffect(() => {
    crossOne.current.classList.add(styles.active);
    crossTwo.current.classList.add(styles.active);

    setTimeout(() => {
      if (
        crossOne.current &&
        crossTwo.current &&
        coverTop.current &&
        coverBottom.current
      ) {
        crossOne.current.classList.add(styles.move);
        crossTwo.current.classList.add(styles.move);
        coverTop.current.classList.add(styles.active);
        coverBottom.current.classList.add(styles.active);
      }
    }, 500);

    setTimeout(() => {
      if (yellowBlock.current) {
        yellowBlock.current.classList.add(styles.active);
      }
    }, 1000);
  });

  const blockColor =
    color === "black"
      ? "bg-brand-black"
      : color === "yellow"
      ? "bg-brand-yellow"
      : color === "green"
      ? "bg-brand-blue"
      : color === "white"
      ? "bg-brand-white border-black border-t border-r border-b border-l"
      : color === "red"
      ? "bg-brand-red"
      : "";

  const titleStringArray = title
    .replace(/(^\s*)|(\s*$)/gi, "")
    .replace(/[ ]{2,}/gi, " ")
    .replace(/\n /, "\n")
    .split(" ");

  const countTitleLength = titleStringArray.length;
  return (
    <div className="relative inline-block" ref={container}>
      <div className={`${styles.crossOne} z-10`} ref={crossOne}></div>
      <div className="relative overflow-hidden">
        <div
          className={`${styles.coverTop} bg-brand-white absolute top-0 left-0 w-full h-1/2`}
          ref={coverTop}
        ></div>
        <h1 className={`${styles.title} page-title font-bold`}>
          {countTitleLength > 1 && title === "Our Approach"
            ? titleStringArray.map((word, i) => (
                <span key={word + i}>{word}</span>
              ))
            : title}
        </h1>
        <div
          className={`${styles.coverBottom} bg-brand-white absolute bottom-0 right-0 w-full h-1/2`}
          ref={coverBottom}
        ></div>
      </div>
      <div className={`${styles.crossTwo} z-10`} ref={crossTwo}></div>
      <div className={`${styles.block} ${blockColor}`} ref={yellowBlock}></div>
    </div>
  );
}

PageTitle.defaultProps = {
  color: "black"
};

export default PageTitle;
